<template>
  <div class="article-box">
    <el-table :data="data" style="width: 100%" v-loading="loading">
      <el-table-column prop="student_id" label="学生ID" align="center" width="150"></el-table-column>
      <el-table-column prop="StuName" label="学生姓名" align="center"></el-table-column>
      <el-table-column prop="SchoolName" label="学校" align="center"></el-table-column>
      <el-table-column prop="TypeName" label="年级" align="center"></el-table-column>
      <el-table-column prop="ClassName" label="班级" align="center"></el-table-column>
      <el-table-column prop="readarticleScore" label="家长看资讯得分" align="center"></el-table-column>
      <el-table-column prop="parentScore" label="家长数量得分" align="center"></el-table-column>
      <el-table-column prop="testScore" label="学生测试得分" align="center"></el-table-column>
      <el-table-column prop="contrastScore" label="学生视力变化得分" align="center"></el-table-column>
      <el-table-column prop="score" label="总得分" align="center" width="150"></el-table-column>
    </el-table>
    <div class="block">
      <el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
        layout="total, prev, pager, next, jumper" :total="CountNum" style="margin-top: 20px">
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import {
    getRankList
  } from '@/api/adminApi.js'
  export default {
    data() {
      return {
        data: [], // 设备列表
        loading: false, //加载框
        currpage: 1, // 初始页
        pagesize: 15, // 每页显示条目个数
        CountNum: 0 // 总条数
      }
    },
    mounted() {
      this.getRankList(1) // 获取第一页设备列表
    },
    methods: {
      getRankList(currpage) { // 设备列表
        this.loading = true
        const data = {
          page: currpage, // 当前页数
          limit: this.pagesize // 每页条数
        }
        getRankList(data).then(res => {
          this.data = res.data.List // 设备列表
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      handleCurrentChange(val) { // 分页
        this.getRankList(val)
        this.currpage = val
      }
    }
  }
</script>

<style>
</style>
